// React
import React, { useRef, useState, useEffect } from 'react';

// Gatsby
import { useStaticQuery, graphql } from 'gatsby';

// Emotion / styling
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import s from 'src/styles';

// Packages
import { useGlobal } from 'reactn';
import MailchimpSubscribe from 'react-mailchimp-subscribe'; // https://www.npmjs.com/package/react-mailchimp-subscribe

// Utilities
import { localize, localizeLink } from 'utilities';
import { transitionNavigate } from 'transition';

// Components
import Button from 'components/button';

const NewsletterComponent = props => {
    const {
        content,
        pageContext: { labels, locale },
    } = props;

    // Refs
    const email = useRef(null);

    // Get theme
    const [theme] = useGlobal('pageTheme');

    return (
        <>
            <Newsletter
                {...{
                    theme,
                }}>
                <Title>{content.title}</Title>
                <Teaser>{content.teaser}</Teaser>
                <MailchimpSubscribe
                    url={
                        'https://coffeecollective.us7.list-manage.com/subscribe/post?u=ccdfd1bb3f5ded0788647ef15&amp;id=9e37610045'
                    }
                    render={({ subscribe, status, message }) => (
                        <>
                            <FormWrapper
                                {...{
                                    theme,
                                }}>
                                <>
                                    {status !== 'success' && (
                                        <>
                                            <input
                                                ref={email}
                                                disabled={status === 'sending'}
                                                type="email"
                                                placeholder={
                                                    content.placeholder
                                                }
                                            />
                                            <Button
                                                {...{
                                                    disabled:
                                                        status === 'sending',
                                                    label: content.callToAction,
                                                    theme,
                                                    arrow: true,
                                                    style: css`
                                                        align-self: flex-start;
                                                    `,
                                                    onClick() {
                                                        const value =
                                                            email.current.value;

                                                        value &&
                                                            value.length > 2 &&
                                                            value.indexOf('@') >
                                                                -1 &&
                                                            subscribe({
                                                                EMAIL: value,
                                                            });
                                                    },
                                                }}
                                            />
                                        </>
                                    )}
                                </>
                            </FormWrapper>
                            <div>
                                {status === 'success' && (
                                    <p>{content.signupSuccess}</p>
                                )}
                                {status === 'error' && (
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: message,
                                        }}
                                    />
                                )}
                            </div>
                        </>
                    )}
                />
            </Newsletter>
        </>
    );
};

const Newsletter = styled.div`
    ${props => s.layout.fullWidthBackground(props.theme.default())};

    ${s.responsive.property(['padding-top', 'padding-bottom'], {
        0: 50,
        20: 80,
        30: 100,
    })};

    display: block;

    color: ${props => props.theme.text()};
    position: relative;
    z-index: ${s.layout.zIndex.subscription};
`;

const Title = styled.div`
    ${s.layout.sectionTitle()};
    position: relative;
`;

const FormWrapper = styled.div`
    display: flex;

    ${s.responsive.property('flex-direction', {
        0: 'column',
        20: 'row',
    })};

    input {
        background: transparent;
        outline: none;
        border: 2px solid ${props => props.theme.text()};
        color: ${props => props.theme.text()};
        padding: 0 10px;
        min-height: 40px;
        min-width: 240px;
        ${s.responsive.property('margin-bottom', {
            0: 10,
            20: '0px',
        })};
        ${s.responsive.property('align-self', {
            0: 'flex-start',
            20: 'stretch',
        })};
        ${s.responsive.property('margin-right', {
            0: 0,
            20: 20,
        })};
        &::placeholder {
            color: ${props => props.theme.text()};
        }
    }

    button {
    }
`;

const Teaser = styled.h2`
    ${s.grid.columns('width', {
        0: 4,
        10: 4,
        20: 6,
        30: 12,
    })};

    ${s.fonts.style('h3')};

    margin-bottom: 0.8em;
    position: relative;
    pointer-events: none;
`;

const Variants = styled.div`
    ${s.fonts.style('h11')};

    ${s.grid.columns('width', {
        0: 4,
        20: 6,
        30: 7,
    })};

    margin-bottom: 30px;
    position: relative;
    pointer-events: none;
`;

export default props => {
    const { locale } = props.pageContext;
    const { allDatoCmsPartialNewsletter } = useStaticQuery(graphql`
        query {
            allDatoCmsPartialNewsletter {
                edges {
                    node {
                        locale
                        title
                        teaser
                        callToAction
                        placeholder
                        signupSuccess
                    }
                }
            }
        }
    `);

    return (
        <NewsletterComponent
            {...{
                content: localize(allDatoCmsPartialNewsletter, locale),
                ...props,
            }}
        />
    );
};
